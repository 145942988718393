.contentStyle {
    background-image: url('../../../public/background.jpg');

    width: 100%;
    height: 90vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}
.formStyle{
    background-color: #f5f5f5;
    height: 90vh;

}
.container{
    width: 100%;
    height: 90vh;
    flex: 1;
}
.loginForm {
    width: 100%;
    background-color: #f4f4f4;
}
.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkButton {
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

}

.footerRow {
    background-color: #00498a;

}
.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.default-font-size {
    font-size: 16px;
}

.mobile-font-size {
    font-size: 14px;
}
.imgMargin{
    margin-top: 70px;
}
.titleMargin{
    margin-top: 20px;
}
.titleForgotPassword{
    font-family: Roboto serif;
    font-size: 26px;
    font-weight: 300;
}
.formMargin{
    margin-top: 60px;
}
.emailInput{
    height: 40px;
    font-family: Roboto serif;
    font-size: 14px;
    font-weight: 300;
}
.tenMargin{
    margin-top: 10px;
}
.fullWidth{
    width: 100%;
}
.footerImg{
    width: 160px;
}
.footerText{
    color: #ffffff;
    font-family: Roboto serif;
    font-weight: 200;
}
.footerImgFederation{
    width: 85px;
}