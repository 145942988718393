.body {
    height: 100%;
    margin: 0;
}

.container {

    height: 100%;
    background-color: #f0f0f0;
}

.content {
    width: 40%;
    height: 100%;
    background-color: #f0f0f0;
    margin: 0 auto;
    text-align: center;
}
.linkButton {
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

}
.createAccountTitle{
    font-family: Roboto serif;
    font-size: 26px;
    font-weight: 300;
    margin-top: 40px;
}
.registerButton{
    height:40px;
    width: 100%;
}