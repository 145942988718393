
.subtitleModal{
    font-size: 16px;
    font-weight: 600;
}
.titleModal{
    font-size: 26px;
    font-weight: 700;
    color: #07305b;
}
.cancelButton{
    display: none;
}
.icon{
    font-size: 21px;
    margin-right: 8px;
}
.cancel{
    display: none;
}