.linkButton {
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.footerRow {
    background-color: #07305b;
}

.default-font-size {
    font-size: 16px;
}

.mobile-font-size {
    font-size: 14px;
}
.eTradeTitleImg{
    margin-top: 70px;
    width: 200px;
}
.tenTopMargin{
    margin-top: 20px;
}

.fullWidth{
    width: 100%;
}
.footerImg{
    width: 125px;
}
.footerText{
    color: #ffffff;
    font-family: Roboto serif;
    font-weight: 200;
}
.footerImgFederation{
    width: 85px;
}