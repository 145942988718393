.fullWidth{
    width: 100%;
}

.submitButton{
    width: 100%;
    background-color: #FFFFFF;
    font-family: 'poppins-bold', sans-serif;
    font-size: 14px;
    font-weight: 400;
    Color: #545454;
    border-color: #545454;
}

.formMargin{
    margin-top: 10px;
}
.defaultInput{
    height: 40px;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 300;
}
.spaceBetweenRows{
    margin-top: 20px;
}

.doubleSpaceBetweenRows{
    margin-top: 35px;
}

.doubleSpaceBetweenRowsTopAndBottom{
    margin-top: 35px;
    margin-bottom: 35px;
}

.select{
    font-family: Roboto serif;
    font-size: 14px;
    font-weight: 300;
}
.inputNumber{
    height: 40px;
    font-family: Roboto serif;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
}
.upload{
    border: 1px solid grey;
    padding: 30px;
    border-radius: 4px;
}
.terms{
    color: #003e86;
}
.fortyTopMargin{
    margin-top: 40px;
}

.payText{
    font-size: 16px;
    font-weight: 700;
    Color: #07305b;
    text-align: center;
}

.chfText{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    Color: #07305b;
}

.yearText{
    font-size: 16px;
    font-weight: 400;
    Color: #07305b;
}

.programBorder{
    display: flex;
    padding: 20px;
    border-color: #545454;
    border: 1px solid;
    border-radius: 5px;
}

.programText{
    font-family: 'poppins-bold', sans-serif;
    font-size: 16px;
    font-weight: 400;
    Color: #333333;
}