@font-face {
    font-family: 'poppins-bold';
    src: url('../../../src/assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins-light';
    src: url('../../../src/assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-bold';
    src: url('../../../src/assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-light';
    src: url('../../../src/assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.poppinsBold{
    font-family: 'poppins-bold', sans-serif;
}
.poppinsLight{
    font-family: 'poppins-light', sans-serif;
}
.robotoBold{
    font-family: 'roboto-bold', sans-serif;
}
.robotoLight{
    font-family: 'roboto-light', sans-serif;
}
.fullWidth{
    width: 100%;
}
.defaultTextInput{
    height: 40px;
    font-family: Roboto serif;
    font-size: 14px;
    font-weight: 300;
}
.footerText{
    color: #ffffff;
    font-weight: 300;
    font-size: 16px;
}