.white-row {
    background-color: white;
}

.left-row {
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.right-row{
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.footerRow {
    background-color: #07305b;

}

.white-row {
    background-color: white;
}

.left-row {
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.right-row{
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.bannerRow{
    align-items: center;
    justify-content: start;
    width: 100%;
}
.imgMargin{
    margin-top: 55px;
}
.imgSize{
    width: 250px;

}

.imgSizeMobile{
    width: 100%;
}
.logout{
    display: flex;
    align-items: end;
    margin-top: 40px;
    justify-content: end;
}
.borderTop{
    border-top: 3px outset #d4d9d6;
    margin: 20px;
}
.smallBorderTop{
    border: 1px solid #d4d9d6;
    margin-top: 10px;
}
.borderBetweenContent{
    border-top: 1px solid #d4d9d6;
    padding: 8px;

}
.content{
    height: 100%;
    margin-bottom: 100px;
}
.profileImg{
    width: 100%;
    /*border-radius:50%;*/
    overflow: hidden;
}
.tenMarginTop{
    margin-top: 10px;
}
.category{
    font-size: 16px;
    font-weight: 700;
    color: #2297ca;
}
.company{
    font-size: 22px;
    font-weight: 600;
    color: #00498a;
}
.address{
    font-size: 14px;
    font-weight: 400;
    color: #545454;
}
.smallAddress{
    font-size: 10px;
    font-weight: 400;
    color: #545454;
}
.countryImg{
    max-height: 20px;
    max-width: 20px;
    margin-right: 5px;

}
.certificateSpace{
    margin-top: 0;
    padding-top: 0;
}
.zeroMarginBottom{
    margin-bottom: 0;
}
.certificateText{
    background: #07305b;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 0;
}
.securedCertificate{
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
}
.securedCertificateSecundary{
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
}
.blockchain{
    font-size: 14px;
    font-weight: 400;
}
.separationP{
    background: #f0eeeb;
    text-align: center;
    padding: 10px;
    margin: 0
}
.id{
    font-size: 18px;
    font-weight: 300;
    color: #07305b;
}
.idNumber{
    font-size: 18px;
    font-weight: 400;
    color: black;
}
.verifiedSeparation{
    margin-bottom: 0;
    margin-top: 0;
}
.verifiedText{
    color: #07305b;
    font-size: 22px;
    font-weight: 700;
}
.thirtyTopMargin{
    margin-top: 30px;
}
.greyColum{
    margin-bottom: 0;
    background-color: #f0eeeb;
}


.zeroMarginTop{
    margin-top: 0;
}
.publicCertificateButton{
    background-color: #07305b;
    width: 100%;
    font-size: 16px;
    font-weight: 800;

}
.centerFull{
    width: 100%;
    align-items: center;
}
.defaultButton{
    background-color: #07305b;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
}

.logoutButton{
    background-color: #07305b;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
}
.colorRow{
    background-color: #f0eeeb;
}
.fiftyTopMargin{
    margin-top: 50px;
}
.informationText{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #545454;
}
.right-col{
    border:5px solid #d4d9d6;

    margin-top: 10px;
}
.twentyTopMargin{
    margin-top: 20px;
}
.rightColImg{
    width: 200px;
    height: auto;
}
.rightColLargeImg{
    width: 300px;
    height: auto;
}
.blackBorderBottom{
    border-bottom: 2px solid #d4d9d6;
}
.rightColText{
    font-size: 14px;
    font-weight: 400;
    color: #07305b;
}
.bannerTopMargin{
    margin-top: 30px;
}
.fullWidth{
    width: 100%;
}
.firstName{
    font-size: 13px;
    font-weight: 600;
    color: #2297ca;
    margin-bottom: 0;
    text-transform: uppercase;
}
.job{
    font-size: 13px;
    font-weight: 400;
    color: #545454;
    margin-bottom: 0;
    margin-top: 0;
}
.email{
    font-size: 13px;
    font-weight: 400;
    color: #2297ca;
    margin-top: 0;
}
.footerText{
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
}

.contentBorder{
    border: 2px solid #07305b;
    margin: 30px 45px 45px;

}
.circularImageWrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.circularImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.titleField{
    font-size: 16px;
    font-weight: 300;
    color: #07305b;
    margin-top: 0;
    margin-bottom: 0px;
    padding-top: 0px;
}
.dataField{
    color: black;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0px;
    padding-top: 0px;


}

.iconCol{
    text-align: center;
    margin-top: 0px;
}
.checkIcon{
    width: 1em;
    height: 1em;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;

}

.downloadButton{
    background: none;
    color: white;
    border: none;
    font-weight: 900;
}