@font-face {
    font-family: 'poppins-bold'; /* Nombre de la fuente */
    src: url('../../../src/assets/fonts/Poppins-Bold.ttf') format('truetype'); /* Ruta a la fuente */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins-light'; /* Nombre de la fuente */
    src: url('../../../src/assets/fonts/Poppins-Light.ttf') format('truetype'); /* Ruta a la fuente */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-bold'; /* Nombre de la fuente */
    src: url('../../../src/assets/fonts/Roboto-Bold.ttf') format('truetype'); /* Ruta a la fuente */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-light'; /* Nombre de la fuente */
    src: url('../../../src/assets/fonts/Roboto-Light.ttf') format('truetype'); /* Ruta a la fuente */
    font-weight: normal;
    font-style: normal;
}

.centerFull{
    width: 100%;
    align-items: center;
}

.tenMarginTop{
    margin-top: 10px;
}

.poppinsBold{
    font-family: 'poppins-bold', sans-serif; /* Utiliza la fuente */
}
.poppinsLight{
    font-family: 'poppins-light', sans-serif;
}
.robotoBold{
    font-family: 'roboto-bold', sans-serif;
}
.robotoLight{
    font-family: 'roboto-light', sans-serif;
}
.white-row {
    background-color: white;
}

.left-row {
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.right-row{
    font-family: 'roboto-light', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.footerRow {
    background-color: #00498a;

}
.eTradeTitleImg{
    width: 200px;
}
.mainDiv{
    margin: 25px;
    display: block;
}
.borderBottom{
    border-bottom:1px solid black
}
.colMarginTen{
    margin-top: 10px;
    margin-bottom: 10px;
}
.marginTopTen{
    margin-top: 10px;
}
.secureSite{
    color: rgb(34, 151, 202);
    font-size: 20px;
}
.validationCertificate{
    font-size: 20px;
    color: grey;
}
.centralConcentSeparation{
    margin-top: 20px;
}
.verifiedDetailCol{
    background-color: #008b8b;
    margin-top:20px;
}
.verifiedDetailText{
    color:white;
    font-size:20px;
    padding:0;
    margin: 5px 0 5px 10px;
}
.title{
    color:black;
    font-size:20px;
    padding:0;
    margin: 5px 0 5px 10px;
}
.subtitle{
    color: grey;
    font-size: 20px;
    padding: 0;
    margin: 5px 0 5px 10px;
}
.checkOutline{
    color: blue;
    margin-right: 10px;
}
.certificateSpace{
    margin-top: 0;
    padding-top: 0;
}
.zeroMarginBottom{
    margin-bottom: 0;
}
.certificateText{
    background: #07305b;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 0;
}
.securedCertificate{
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
}
.securedCertificateSecondary{
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
}
.id{
    font-size: 18px;
    font-weight: 300;
    color: #07305b;
}
.idNumber{
    font-size: 18px;
    font-weight: 400;
    color: black;
}
.separationP{
    background: #f0eeeb;
    text-align: center;
    padding: 10px;
    margin: 0
}
.verifiedText{
    color: #07305b;
    font-size: 22px;
    font-weight: 700;
}
.greyColum{
    margin-bottom: 0;
    background-color: #f0eeeb;
}
.borderBetweenContent{
    border-top: 1px solid #d4d9d6;
    padding: 8px;

}
.titleField{
    font-size: 16px;
    font-weight: 300;
    color: #07305b;
    margin-top: 0;
    margin-bottom: 0px;
    padding-top: 0px;
}
.dataField{
    color: black;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0px;
    padding-top: 0px;


}
.clickHere{
    font-size: 16px;
    font-weight: 400;

}
.iconCol{
    text-align: center;
    margin-top: 0px;
}
.checkIcon{
    width: 1em;
    height: 1em;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;

}
.colorRow{
     background-color: #f0eeeb;
 }
.zeroMarginBottom{
    margin-bottom: 0;
}
.zeroMarginTop{
    margin-top: 0;
}

.downloadButton{
    background: none;
    color: white;
    border: none;
    font-weight: 900;
}

.logoImg{
    max-width: 200px;
    height: auto;
}
