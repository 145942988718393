.filterDiv{
    padding: 8px;
}
.inputSearch{
    display: block;
    margin-bottom: 8px;
}
.searchButton {
    width: 90px;
}
.highlighted {
    background-color: #ffc069;
    padding: 0;
}
