

.contentStyle {
    background-image: url('../../../public/background.jpg');

    width: 100%;
    height: 90vh;

}
.container{
    width: 100%;
    height: 90vh;
    flex: 1;
}
.loginForm {
    width: 100%;
    background-color: #f4f4f4;
}
.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkButton {
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

}

.footerRow {
    background-color: #00498a;
    color: #ffffff;
    width: 100%;
    height: 100%;
    flex-grow: 1;

}
.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.default-font-size {
    font-size: 16px;
}

.mobile-font-size {
    font-size: 14px;
}
.buttonWidth{
    width: 93%;
    font-size: 20px;
    height: fit-content !important;
}