.form{
    width: 100%;
}

.textInput{
    height: 40px;
    font-family: 'Roboto', serif;
    font-size: 14px;
    font-weight: 300;
}

.separation{
    padding: 20px;
    margin-top:30px;
    margin-left:30px;
}

.separationOnlyPadding{
    padding: 20px;
}

.titleList{
    font-size: 16px;
    color: black;

}
.subtitleList{
    color: grey;

}